.header {
  margin-top: 40px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.tagline-container {
  flex-direction: column;
}

.tagline-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
}

.tagline-subtitle {
  font-size: 20px;
  margin-top: 5px;
}

@keyframes button-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button-container {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  animation: button-fade-in 0.625s ease;
  opacity: 1;
}

.button {
  padding: 15px 50px;
  min-width: 200px;
}

.button:disabled {
  background-color: #cdd4db;
  color: #7a8d9f;
  cursor: not-allowed;
}

.marquee {
  margin-top: 40px;
  overflow: hidden;
  white-space: nowrap;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  rotate: -2deg;
}

.marquee::before {
  left: 0;
  top: 0;
}

.marquee::after {
  right: 0;
  top: 0;
  transform: rotate(180deg);
}

.marquee::before,
.marquee::after {
  background: linear-gradient(
    to right,
    #06152a 0%,
    rgba(255, 255, 255, 0) 100%
  );
  pointer-events: none;
  content: "";
  height: 100%;
  position: absolute;
  width: 100px;
  z-index: 1;
}

.upsell {
  display: flex;
  flex-direction: column;
}

.upsell-section-content {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.upsell-section-content img,
.upsell-section-content svg {
  height: 400px;
  width: 600px;
  max-width: 600px;
  object-fit: contain;
  margin: 100px;
}

.upsell-section-content div {
  display: flex;
  flex-direction: column;
  margin: 100px;
}

.upsell-section-white {
  background-color: white;
}

.upsell-section-dark {
  background-color: #f3f8fd;
}

.upsell-section-use-now {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.upsell-title {
  color: #06152a;
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
}

.upsell-text {
  color: #06152a;
  font-weight: 450;
  font-size: 20px;
}

.upsell-small-print {
  color: #06152a;
  font-weight: 275;
  font-size: 15px;
  font-style: italic;
}

.upsell-button {
  margin-top: 25px;
}