.section {
  padding-top: 50px;
  position: relative;
  height: 100%;
  padding-bottom: 20px;
}

.text-black {
  color: #7a8d9f;
  fill: #7a8d9f;
}

.text-black.section {
  background-color: #06152a;
}

.content {
  margin: 0 auto;
  max-width: 80%;
  position: relative;
}

.navigation {
  display: flex;
  justify-content: space-between;
}

.navigation-section {
  display: flex;
  flex-direction: column;
  margin: 0 16px;
}

.navigation-header {
  font-size: 1.27rem;
  color: white;
  font-size: 14px;
  margin-bottom: 13px;
}

.navigation-link {
  transition: color 0.25s;
  will-change: color;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.navigation-link a {
  text-decoration: none !important;
  color: inherit;
}

.navigation-link:hover {
  color: white;
}

.logo {
  fill: white;
  height: 36px;
  width: 36px;
}

.separator {
  margin-top: 20px;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
}

.upsell {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.upsell-text {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.upsell-text-title {
  color: white;
  font-size: 28px;
}

.upsell-text-subtitle {
  font-size: 18px;
  font-weight: 500;
  margin-top: 5px;
}

.button {
  padding: 12px;
}

.notice {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
