.header {
  margin-top: 40px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.tagline-container {
  flex-direction: column;
}

.tagline-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
}

.tagline-subtitle {
  font-size: 20px;
  margin-top: 5px;
  text-align: center;
}