.section {
  background-color: transparent;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 400;
  height: 90px;
  position: relative;
  width: 100%;
  align-content: center;
}

.inner {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  flex-wrap: wrap;
}

.logo-link {
  display: flex;
  align-items: center;
}

.logo {
  height: 25px;
}

.navigation {
  align-items: center;
  list-style: none;
  display: flex;
  flex: 1 0 auto;
  padding: 0;
  margin: 0;
}

.navigation-right {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  margin-left: auto;
}

.list-item {
  display: inline-block;
  position: relative;
  transition: color 0.25s, border 0.25s, fill 0.25s;
  will-change: color, border, fill;
  cursor: pointer;
  align-content: center;
}

.list-item a {
  text-decoration: none !important;
  color: inherit;
}

.list-item-socials {
  width: 25px;
  height: 25px;
  transition: color 0.25s, fill 0.25s;
}

.list-item-push-left {
  margin-left: 20px;
}

.list-item-push-right {
  margin-right: 15px;
}

.open-button {
  top: 0;
  padding: 5px 17px 7px;
  border-radius: 20px;
  line-height: 24px;
  margin-left: 5px;
}

.text-light-gray .list-item,
.text-light-gray .list-item-socials {
  color: #c7cfd7;
  fill: #c7cfd7;
}

.text-light-gray .open-button {
  border: 2px solid #c7cfd7;
}

.text-dark-gray .list-item,
.text-dark-gray .list-item-socials {
  color: #7a8d9f;
  fill: #7a8d9f;
}

.text-dark-gray .open-button {
  border: 2px solid #7a8d9f;
}

.text-black .list-item,
.text-black .list-item-socials {
  color: #06152a;
  fill: #06152a;
}

.text-light-gray .logo,
.text-dark-gray .logo {
  fill: white
}

.text-black .logo {
  fill: #06152a
}

.text-black .open-button {
  border: 2px solid #06152a;
}

.text-light-gray .list-item:hover,
.text-light-gray .list-item-socials:hover,
.text-dark-gray .list-item:hover,
.text-dark-gray .list-item-socials:hover {
  color: white;
  fill: white;
}

.text-light-gray .open-button:hover,
.text-dark-gray .open-button:hover {
  border: 2px solid white;
}
