.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #06152a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  background: white;
  padding: 24px 32px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  width: 750px;
  flex-direction: column;
  display: flex;
}

.title {
  font-size: 28px;
  font-weight: 600;
}

.subtitle {
  color: #7a8d9f;
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
}

.bold {
  font-weight: 600;
}

.i-understand {
  background-color: #e2ecfb;
  margin-bottom: 20px;
  padding: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  padding: 10px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.close-button {
  font-size: 30px;
  text-overflow: clip;
  cursor: pointer;
  transform: translateY(-10px);
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.checkbox input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.checkmark {
  min-height: 20px;
  min-width: 20px;
  background-color: #f3f8fd;
  border: 2px solid #ddebf9;
  border-radius: 0;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox input:checked + .checkmark {
  background-color: #1f6ad3;
  border-color: #1f6ad3;
}

.checkmark:after {
  content: "";
  display: none;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox input:checked + .checkmark:after {
  display: block;
}
