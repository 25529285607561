.header {
  margin-top: 40px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.tagline-container {
  flex-direction: column;
}

.tagline-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
}

.tagline-subtitle {
  font-size: 20px;
  margin-top: 5px;
}

.button {
  padding: 15px 50px;
  min-width: 200px;
}

.download {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.download-section-content {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.download-title {
  color: #06152a;
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
}

.download-text {
  color: #06152a;
  font-weight: 450;
  font-size: 20px;
}

.download-button {
  margin-top: 25px;
}

.download-section-content-os {
  display: flex;
  flex-direction: column;
  align-items: center;
}
