.header {
  margin-top: 40px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.tagline-container {
  flex-direction: column;
}

.tagline-logo {
  height: 75px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  fill: white;
  padding-bottom: 10px;
}

.tagline-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 20px;
  text-align: center;
}

.button {
  padding: 15px 50px;
  min-width: 200px;
}

.upsell-section {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.upsell-title {
  color: #06152a;
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  white-space: nowrap;
  height: max-content;
}

.upsell-title svg {
  min-width: 32%;
  width: 32%;
  margin-left: 10px;
  transform: translateY(2.5px);
}

.upsell-text {
  color: #06152a;
  font-weight: 450;
  font-size: 20px;
  text-align: center;
}

.upsell-button {
  margin-top: 25px;
}
