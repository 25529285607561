.section {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.section-content {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 20px;
  margin-bottom: 60px;
}

.title {
  color: #06152a;
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
  margin-top: 20px;
}

.subtitle {
  color: #06152a;
  font-weight: 500;
  font-size: 25px;
  line-height: 44px;
}

.text {
  color: #06152a;
  font-weight: 450;
  font-size: 20px;
  margin-bottom: 20px;
}

Link, a {
    text-decoration: none;
}

.bold {
  font-weight: 600;
}

.logo {
  width: 400px;
  padding: 60px;
}

li.text {
  margin-bottom: 0;
}

.section ul {
  margin-top: 0;
  margin-bottom: 10px;
}
