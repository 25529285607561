body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.bg-hurple {
  background-color: #1f6ad3;
  color: white;
}

.bg-dark {
  background-color: #06152a;
  color: white;
}

.bg-white {
  background-color: white;
  color: #06152a;
}

.text-light-gray {
  color: #c7cfd7;
  fill: #c7cfd7;
}

.text-dark-gray {
  color: #7a8d9f;
  fill: #7a8d9f;
}

.text-black {
  color: #06152a;
  fill: #06152a;
}

.button {
  border-radius: 3px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  text-align: center;
}

.button-disabled {
  background-color: #cdd4db;
  color: #7a8d9f;
  cursor: not-allowed;
}

.button-link {
  text-decoration: none;
  color: inherit;
}

.button-pushLeft {
  margin-right: 40px;
}

.button-white {
  background-color: white;
  color: #06152a;
}

.button-hurple {
  background-color: #1f6ad3;
  color: #fff;
}

.text-light-gray .button-hurple {
  background-color: #1f46d3;
  color: #fff;
}

.fade {
  opacity: 0.5;
}
